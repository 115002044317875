"use client"

import type { FunctionComponent, PropsWithChildren } from "react"

import classnames from "classnames"
import { useState } from "react"

import { Icon } from "@/modules/headerFooterFO/Header/components/Icon/Icon"

import dropdownMenuStyles from "./styles/DropdownMenu.module.css"

type AppDropdownButtonProps = PropsWithChildren<{
  ariaLabel: string
}>

export const AppDropdownButton: FunctionComponent<AppDropdownButtonProps> = ({ ariaLabel }) => {
  const [isMobileMenuOpen, setIsMenuMobileOpen] = useState(false)

  const toggleMobileMenuClick = (): void => {
    setIsMenuMobileOpen(!isMobileMenuOpen)
    document.body.classList.toggle("app-Body--noScroll")
    const headerWrapper = document.querySelector(".js-Header-wrapper")
    headerWrapper?.classList.toggle("nav--open")
  }

  return (
    <button
      type="button"
      className={classnames(dropdownMenuStyles["hdr-DropdownMenu-link"], "js-Header-menuLink")}
      aria-label={ariaLabel}
      onClick={toggleMobileMenuClick}
    >
      {isMobileMenuOpen ? <Icon name="Cross" isBig /> : <Icon name="BurgerMenu" isBig />}
    </button>
  )
}
